.authincation{
	background:var(--rgba-primary-1);
	display:flex;
	min-height: 100vh;
}
.authincation-content{
    background: $white;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-radius: 5px;
    @at-root [data-theme-version="dark"] & {
        background: $dark-card;
        box-shadow: none;
    }

}

.welcome-content{
    // background: url('./../images/1.jpg');
    background-size: cover;
    background-position: center;
    height: 100%;
    padding: 75px 50px;
    position: relative;
    z-index: 1;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: $primary;
        opacity: 0.75;
        z-index: -1;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    .welcome-title{
        color: $white;
        font-weight: 500;
        font-size: 20px;
    }
    p{
        color: $white;
    }

    .brand-logo{
        a{
            display: inline-block;
            margin-bottom: 20px;
            font-weight: 700;
            color: $white;
            font-size: 20px;
        img{
            width: 100px;
        }
        }
    }

    .intro-social{
        position: absolute;
        bottom: 75px;
        ul{
            margin-bottom: 0;
            li{
                display: inline-block;
                a{
                    color: $white;
                    font-size: 14px;
                    padding: 0px 7px;
                }
            }
        }
    }
}

.auth-form{
    padding: 50px 50px;
    .btn{
        height: 50px;
        font-weight: 700;
    }
    .page-back{
        display: inline-block;
        margin-bottom: 15px;
    }
}




.login-account{
	padding: 40px;
    border-top: 1px solid rgba(0,0,0,0.05);
	background-color: #fff;
	
	@include respond ('phone'){
		padding: 20px;
	}
	.account-info-area{
		background-color: $primary;
		border-radius: 2.5rem;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 1;
		overflow: hidden;
		height: calc(100vh - 80px);
		min-height:600px;
		background-repeat: no-repeat;
		background-size: cover;
		background-blend-mode: luminosity;
		
		&:after{
			content: "";
			height: 100%;
			width: 100%;
			background-color: $primary;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0.9;
		}
		
		@media only screen and (max-width: 1024px){
			min-height:570px;
		}
		@include respond ('tab-port'){
			min-height:500px;
		}
		@media only screen and (max-width: 768px){
			min-height:450px;
			height:450px;
			margin-bottom: 50px;
		}
		@media only screen and (max-width: 600px){
			min-height:400px;
			height:400px;
		}
		@include respond ('phone'){
			min-height: 350px;
			height: 350px;
			border-radius: 10px;
		}
		.login-bg{
			transform: scale(1.3) translateX(-5%);
			@media only screen and (max-width: 1024px){
				transform: scale(1.8) translateX(-7%);
			}
			@include respond ('phone'){
				transform: scale(1) translateX(0) translateY(-60%);
			}
		}
		.text{
			max-width: 420px;
			margin: 0 auto;
			color: #fff;
			opacity: 0.8;
		}
		.login-content{
			text-align: center;
			padding: 120px 10px;
			z-index: 9;
			position: relative;
			max-width: 500px;
			margin-left: auto;
			margin-right: auto;
	
			.logo{
				margin-bottom:15px;
			}
			.title{
				font-size: 65px;
				line-height: 1.2;
				font-weight: 700;
				margin-bottom: 1.4rem;
				margin-top: 0;
				color: #fff;
				
				span{
				    text-decoration: underline;
				}
			}
			.sub-title{
				font-size: 28px;
				margin-bottom: 15px;
				font-weight: 500;
				line-height: 1.35;
				padding: 0 4px;
				color: #fff;
			}
			@media only screen and (max-width: 1320px){
				padding: 120px 5px 120px 35px;
			}
			@include respond ('laptop'){
				.title{
					font-size: 50px;
				}
				.sub-title{
					font-size: 20px;
				}
			}
			@media only screen and (max-width: 1024px){
				padding: 120px 5px 120px 5px;
			}
			@include respond ('tab-port'){
				padding: 120px 70px 120px 0px;
				.title{
					font-size: 45px;
				}
				.sub-title{
					font-size: 25px;
				}
			}
			@media only screen and (max-width: 768px){
				padding: 120px 25px 120px 25px;
				.title{
					font-size:40px;
				}
				.sub-title{
					font-size: 20px;
				}
			}
			@include respond ('phone-land'){
				padding: 100px 5px 120px 20px;
				.title{
					font-size:35px;
					margin-bottom:1rem;
				}
				.sub-title{
					font-size: 19px;
				}
			}
			@include respond ('phone'){
				padding: 45px 20px;
				.title{
					font-size:30px;
					margin-bottom:1rem;
				}
				.sub-title{
					font-size: 16px;
				}
			}
		}
	}
	.social-btn{
	    padding: 10px 10px;
	}
	.apple{
		@include respond('phone'){
			margin-top:10px;
		}
		&:hover{
			svg{
				path{
					fill:$white;
				}
			}
		}
	}




	.login-form{
		padding: 0 50px;
		max-width: 600px;
		margin: 0 auto;
		
		@include respond ('wide-desktop'){
			padding: 0 30px;
		}
		@include respond ('laptop'){
			padding: 0 15px;
		}
		@media only screen and (max-width: 1024px){
			padding: 0;
		}
		.login-head{
			text-align: center;
			margin-bottom: 2rem;
			.title{
				font-size:36px;
				font-weight: 600;
				
				@media only screen and (max-width: 1024px){
					font-size:23px;
				}
				@include respond ('phone'){
					font-size:20px;
				}
			}
		}
		.login-title{
			text-align: center;
			position: relative;
			margin-bottom: 48px;
			z-index: 1;
			display: flex;
			align-items: center;
			&:before, &:after{
				content: "";
				height: 1px;
				flex: 1 1;
				left: 0;
				background-color:#E1E1F0;
				top: 50%;
				z-index: -1;
				margin: 0;
				padding: 0;
			}
			
			span{
				padding: 0 18px;
			}
			@media only screen and (max-width: 1200px){
				margin-bottom: 25px;
			}
		}
		.secure-input {
			position: relative;
			
			.show-pass{
				cursor: pointer;
				position: absolute;
				right: 0;
				height: auto;
				width: 40px;
				top: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 18px;
				
				&:after{
					content: "";
					height: 2px;
					width: 24px;
					background-color: #777777;
					position: absolute;
					border-radius: 5px;
					transform: rotate(15deg);
					opacity: 0;
				}
				
				&.active{
					&:after{
						opacity: 1;
					}
				}
			}
		}
		
	}
}

